import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import React from 'react';
import { titleCase, numberWithSpaces, smartRound } from '../../util';
import ProgressBar from '../fields/ProgressBar/ProgressBar';
import { displayDefaultTableCell } from './util';

const getSideColor = ( side, theme ) => {
  if( side === 'buy'){
    return theme.palette.success.main
  } if (side === 'sell'){
    return theme.palette.error.main
  }
  return theme.palette.text.primary
}


const parseStatus = (status) => {

  switch(status) {
  case 'SUBMITTED':
    return <Typography color='primary.main'>
        Submitted
    </Typography>
  case 'CANCELED':
    return <Typography color='error.main'>
        Canceled
    </Typography>
  case 'COMPLETE':
    return <Typography color='success.main'>
        Finished
    </Typography>
  case 'SCHEDULED':
    return <Typography color='secondary.main'>
        Scheduled
    </Typography>
  case 'PAUSED':
    return <Typography color='info.main'>
        Paused
    </Typography>
  default:
    return <Typography color='primary.main'>
        Active
    </Typography>
  }
}

const parseSuperStrategy = (row, value, column ) => {
  let displayText = value

  if( row.side === 'Multi' || row.side === 'Chained'){
    displayText = row.strategy
  }

  if ( row.order_condition_normal ) {
    displayText += '*'
  }

  return displayDefaultTableCell(column, displayText)
}

export default function DisplayRowDetails({row, column, StyledCell, theme}) {
  let value = row[column.id];
  switch(column.id){
  case 'pair':
    if((row.pairs && ( row.side === 'Multi' || row.side === 'Chained')) ){
      return displayDefaultTableCell(column, row.pairs)
    }
    return (
      <StyledCell align={column.align} key={column.id}>
        <b>
          {value  }
        </b>
      </StyledCell>
    );

  case 'executed_notional':
    value = `$${numberWithSpaces(Number(row[column.id]).toFixed(3))}`;
    return displayDefaultTableCell(column, value, {whiteSpace: 'nowrap'})

  case 'status':
    return (
      <StyledCell align={column.align} key={column.id}>
        {parseStatus(row.status)}
      </StyledCell>
    );


  case 'account_names':
    if(row.account_names === undefined || row.account_names.length === 0) {
      return (<StyledCell align={column.align} key={column.id}>
        <i>[Deleted]</i>
      </StyledCell>);
    }
    return displayDefaultTableCell(column, value)

  case 'pct_filled':
    return (
      <StyledCell align={column.align} key={column.id} >
        <Box alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center">
          <ProgressBar
            isPov={row.pov_limit || row.pov_target}
            orderStatus={row.status}
            progress={Math.round(Number(value))}
          />
        </Box>
      </StyledCell>
    );

  case 'super_strategy':
    return parseSuperStrategy(row, value, column)

  case 'side':
    return displayDefaultTableCell(
      column,
      titleCase(value),
      {color: getSideColor(value, theme)}
    )

  case 'time_start':
    if (value) {
      return displayDefaultTableCell(column, value.substring(0, value.length - 4));
    }
    return displayDefaultTableCell(column, '');



  case 'executed_price':
    return displayDefaultTableCell(column, value ? `$${value.toFixed(6)}` : '')

  case 'target_qty':
    if(row.side === 'Multi' || row.side === 'Chained'){
      return displayDefaultTableCell(column, null)
    }

    return ( row.buy_token_amount ? (
      displayDefaultTableCell(column, `${smartRound(Number(row.buy_token_amount))} ${
        row.market_type !== 'option' ? row.buy_token : 'Contracts'}` )
    ) : (
      displayDefaultTableCell(column, `${smartRound(Number(row.sell_token_amount))} ${
        row.market_type !== 'option' ? row.sell_token : 'Contracts'}` )
    ))
  default:
    return displayDefaultTableCell(column, value)
  }
}
