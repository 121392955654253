import { useTheme } from '@mui/material/styles';
import { Stack, TableHead, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { StyledBenchmarkTableCell, StyledIBMTypography, StyledSummaryTableCell } from '../../../shared/orderTable/util';

function ChainedOrderSummary({ orderSummaryData, accountNames }) {
  const theme = useTheme();

  if (!orderSummaryData || Object.keys(orderSummaryData).length === 0) {
    return (
      <Box alignItems="center" display="flex" height="100%" justifyContent="center">
        <ScaleLoader color={theme.palette.text.offWhite} />
      </Box>
    );
  }

  const {
    id,
    account_names = accountNames,
    buy_token,
    sell_token,
    pct_filled, 
    notional_exposure,
    executed_notional,
    buy_token_amount,
    sell_token_amount,
    time_start,
    strategy,
    strategy_params,
    status,
  } = orderSummaryData;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledSummaryTableCell align="left" colSpan="2">
            <Typography color="primary" variant="h6">
              {id}
            </Typography>
          </StyledSummaryTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <StyledSummaryTableCell><Typography>Accounts</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{account_names?.length ? account_names.join(' | ') : 'N/A'}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Pair</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{`${buy_token}-${sell_token}`}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Executed Notional</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>${Number(executed_notional).toFixed(6)}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Notional Exposure</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>${Number(notional_exposure).toFixed(6)}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Filled Percentage</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{pct_filled}%</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Buy Token Amount</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{buy_token_amount ? Number(buy_token_amount).toFixed(6) : 'N/A'}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Sell Token Amount</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{sell_token_amount ? Number(sell_token_amount).toFixed(6) : 'N/A'}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Start Time</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{time_start ? new Date(time_start).toLocaleString() : 'N/A'}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Strategy</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{strategy || 'N/A'}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Strategy Params</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{strategy_params ? JSON.stringify(strategy_params) : 'None'}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell><Typography>Status</Typography></StyledSummaryTableCell>
          <StyledSummaryTableCell>
            <Typography>{status}</Typography>
          </StyledSummaryTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export {ChainedOrderSummary}