import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Collapse, Box, Table, TableBody, TableRow } from '@mui/material';
import { getBulkChainedOrders } from '../../apiServices';
import DisplayRowDetails from './DisplayRowDetails';
import { ThinLoader } from '../Loader';


export default function CollapsedChainedsRow({ ordersInChain, StyledCell, columns, open, ViewOrderTooltip }) {
  const [chainedOrderDetails, setChainedOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();

  async function fetchChainedOrders(orders) {
    try {
      const fetchedDetails = await getBulkChainedOrders(orders);
      setChainedOrderDetails(fetchedDetails);
    } catch (error) {
      console.error("Error fetching child orders: ", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      fetchChainedOrders(ordersInChain);
    }
  }, [open]);
  return (
    <Collapse in={open}>
      {loading || !chainedOrderDetails ? (
        <>
          {ordersInChain.map((ChainedId) => (
            <Box key={ChainedId.id || ChainedId} style={{ padding: '10px' }}>
              <ThinLoader />
            </Box>
          ))}
        </>
      ) : (
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableBody>
            {chainedOrderDetails.map((row) => {
              const order = row.order;
              return (
                <TableRow
                  hover
                  key={order.id}
                  sx={{
                    '& .MuiTableCell-root': {
                      borderBottom: 0,
                    },
                    width: '70%',
                    justifyContent: 'flex-end',
                    alignItems: 'right',
                  }}
                >
                  <StyledCell align="left" key={`empty_child_cell_${order.id}`} style={{ width: 50 }} />
                  {columns.map((column) => (
                    <DisplayRowDetails key={column.id} row={order} column={column} StyledCell={StyledCell} theme={theme} />
                  ))}
                  <StyledCell
                    sx={{
                      height: 32,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      textAlign: 'end',
                    }}
                  >
                    <ViewOrderTooltip row={order} theme={theme} />
                  </StyledCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Collapse>
  );
}
